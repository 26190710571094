export const BOOTSTRAP_DATA = 'bootstrapData'
export const INSTANCE_CACHE = 'instanceCache'
export const PLATFORM_LOGGER = 'platformLogger'
export const SESSION_SERVICE = 'sessionService'
export const VIEWER_HANDLERS = 'viewerHandlers'
export const UNFINISHED_TASKS = 'unfinishedTasks'
export const CLIENT_SPEC_MAP_API = 'clientSpecMapApi'
export const MODELS_API_PROVIDER = 'modelsApiProvider'
export const PLATFORM_ESSENTIALS = 'platformEssentials'
export const INVOKE_VIEWER_HANDLER = 'invokeViewerHandler'
export const MODELS_PROVIDER_FACTORY = 'modelsProviderFactory'
export const PLATFORM_BI_LOGGER = 'platformBiLogger'
