import _ from 'lodash'
import { getModules } from '@wix/simple-module-loader'
import { getComponentsSDKLoader } from '@wix/thunderbolt-components-registry/getComponentsSDKLoader'
import { createLinkUtils, createPromise, logSdkError, logSdkWarning, createProxy } from '@wix/thunderbolt-commons'
import type { IPlatformLogger, PlatformEnvData, StorageInitData, CompProps } from '@wix/thunderbolt-symbols'
import { createStorageAPI } from '../storage/storageAPI'
import type { ComponentSdksLoader, CoreSdkLoaders, CreateWixStorageAPI, WixStorageAPI } from '../types'
import type { ControllersExports, InitArgs } from './types'
import { AppsUrls } from './appsUrls'
import WixSelector from './wixSelector'
import WixCodeViewerAppUtils from './wixCodeViewerAppUtils'
import BlocksAppsUtils from './blocksAppsUtils'
import DataBindingViewerAppUtils from './dataBindingViewerAppUtils'
import { Applications } from './applications'
import { createWixCodeApiFactory } from './createWixCodeSdk'
import createSdkFactoryParams from './createSdkFactoryParams'
import setPropsFactory from './setPropsFactory'
import { ControllerEvents } from './ControllerEvents'
import { DocumentSdkFactory } from './componentsSDK/Document'
import { createPlatformApi } from './appsAPI/platformAPI'
import CommonConfigManager from './commonConfigModule'
import BsiManagerModule from './bsiManagerModule'
import { createWixCodeNamespacesRegistry } from './WixCodeNamespacesRegistry'

import { componentSdkStateFactory } from './componentSdkState'
import { ComponentSdksManagerFactory } from './componentSdksManager'
import { RegisterEventFactory } from './createRegisterEvent'
import { PlatformAnimationsAPI } from '../animations'
import { CreateStaticEventsManager } from './staticEventsManager'
import { AppsPublicApi } from './appsPublicApi'
import { BuildPlatformUtils } from './buildPlatformUtils'
import { CreateLocationManager } from './locationManager'
import { CreateWarmupDataManager } from './warmupDataManager'
import { CreateConsentPolicyManager } from './consentPolicyManager'
import { FedopsWebVitalsManager } from './fedops'
import { SsrCacheHintsManager } from './ssr'
import { ModuleFederationManagerFactory } from './moduleFederationManager'
import { PlatformBI } from './bi'
import InstanceCache from './modules/instanceCache'
import { PlatformLogger } from './modules/platformLogger'
import { ViewerHandlers } from './modules/viewerHandlers'
import { UnfinishedTasks } from './modules/unfinishedTasks'
import ClientSpecMapApi from './modules/clientSpecMapApi'
import { ModelsApiProvider } from './modules/modelsApiProvider'
import PlatformEssentials from './modules/platformEssentials'
import PlatformBiLogger from './modules/platformBiLogger'

type PlatformState = {
	createStorageApi: CreateWixStorageAPI
	loadComponentSdksPromise: Promise<ComponentSdksLoader>
}

export function createPlatformAPI() {
	const { promise: waitForInit, resolver: initDone } = createPromise<PlatformState>()

	return {
		initPlatformOnSite({ logger, platformEnvData }: { logger: IPlatformLogger; platformEnvData: PlatformEnvData }) {
			const siteStorageApi: CreateWixStorageAPI = createStorageAPI()

			initDone({
				createStorageApi: (appPrefix: string, handlers: any, storageInitData: StorageInitData): WixStorageAPI => {
					return siteStorageApi(appPrefix, handlers, storageInitData)
				},
				loadComponentSdksPromise: getComponentsSDKLoader({
					platformEnvData,
					logger,
				}) as any, // TODO: remove `as any` after https://github.com/wix-private/editor-elements/pull/3443 is merged
			})
		},

		async runPlatformOnPage({
			bootstrapData,
			importScripts,
			moduleLoader,
			invokeViewerHandler,
			modelsProviderFactory,
			sessionService,
			debugApi,
			flushPendingUpdates = _.noop,
			onPageWillUnmount,
		}: InitArgs) {
			const viewerHandlers = ViewerHandlers(invokeViewerHandler, bootstrapData)
			const unfinishedTasks = UnfinishedTasks(viewerHandlers)
			const logger = PlatformLogger(bootstrapData, sessionService, unfinishedTasks)
			logger.interactionStarted('initialisation')
			const modelBuilder = ModelsApiProvider(bootstrapData, modelsProviderFactory, logger)
			const modelsApi = await logger.runAsyncAndReport('getAllModels', modelBuilder.getModelApi)

			const modules = await getModules({
				bootstrapData,
				sessionService,
				invokeViewerHandler,
				modelsProviderFactory,
				InstanceCache,
				ClientSpecMapApi,
				PlatformEssentials,
				PlatformBiLogger,
				modelsApi,
			})

			const { createViewerHandlers, viewerHandlers: handlers } = viewerHandlers
			const { instanceCache: sdkInstancesCache, clientSpecMapApi, platformEssentials: essentials, platformBiLogger: biUtils } = modules

			const appsPublicApiManager = AppsPublicApi({
				modelsApi,
				clientSpecMapApi,
				logger,
				handlers,
				bootstrapData,
				importScripts,
			})

			const platformEnvData = bootstrapData.platformEnvData

			const platformBi = PlatformBI({ biUtils, platformEnvData, modelsApi })
			platformBi.reportWidgetsOnPage()

			if (_.isEmpty(modelsApi.getApplications())) {
				if (modelsApi.hasTPAComponentOnPage()) {
					// a TPA component may Wix.SuperApps.getPublicAPI(). the below code resolves this promise.
					appsPublicApiManager.registerPublicApiProvider((appDefinitionId) => {
						appsPublicApiManager.resolvePublicApi(appDefinitionId, null)
					})
				}
				return
			}

			if (!bootstrapData.platformEnvData.window.isSSR) {
				handlers.componentsLoader
					.registerOnPropsChangedHandler(bootstrapData.currentContextId, (changes: CompProps) => {
						_.forEach(changes, (newProps, compId) => {
							modelsApi.updateProps(compId, newProps)
						})
					})
					.then(onPageWillUnmount)
			}

			const fedopsWebVitalsManager = FedopsWebVitalsManager({
				platformEnvData,
				modelsApi,
				handlers,
			})
			fedopsWebVitalsManager.registerWidgets()

			const ssrCacheHintsManager = SsrCacheHintsManager({ platformEnvData, modelsApi, handlers })
			ssrCacheHintsManager.setSsrCacheHints()

			const { createStorageApi, loadComponentSdksPromise } = await waitForInit
			const componentSdksManager = ComponentSdksManagerFactory({ loadComponentSdksPromise, modelsApi, logger })
			const getCompRefById = (compId: string) =>
				createProxy((functionName: string) => (...args: any) => {
					// wait for all stores to be updated before a potential (re)render of a component.
					// specifically, when changing a state of a state box, we want the target state props to be ready.
					flushPendingUpdates()
					return handlers.invokeCompRefFunction(compId, functionName, args)
				})
			const appsUrlsManager = AppsUrls({ bootstrapData })
			const controllerEventsFactory = ControllerEvents()
			const componentSdkState = componentSdkStateFactory()
			const commonConfigManager = CommonConfigManager(bootstrapData, createViewerHandlers, onPageWillUnmount)
			const consentPolicyManager = CreateConsentPolicyManager({ handlers, platformEnvData })
			const bsiManager = BsiManagerModule(commonConfigManager, consentPolicyManager, bootstrapData, createViewerHandlers, onPageWillUnmount)
			const linkUtils = createLinkUtils({
				isMobileView: bootstrapData.isMobileView,
				getCompIdByWixCodeNickname: modelsApi.getCompIdByWixCodeNickname,
				getRoleForCompId: modelsApi.getRoleForCompId,
				routingInfo: platformEnvData.router.routingInfo,
				metaSiteId: platformEnvData.location.metaSiteId,
				userFileDomainUrl: platformEnvData.location.userFileDomainUrl,
				routersConfig: bootstrapData.platformAPIData.routersConfigMap,
				popupPages: platformEnvData.popups?.popupPages,
				multilingualInfo: platformEnvData.multilingual,
				isPremiumDomain: platformEnvData.location.isPremiumDomain,
				experiments: platformEnvData.site.experiments,
			})
			const wixCodeNamespacesRegistry = createWixCodeNamespacesRegistry()
			const locationManager = CreateLocationManager({ handlers, platformEnvData, bootstrapData })
			const warmupDataManager = CreateWarmupDataManager({ handlers, platformEnvData })
			const platformUtils = BuildPlatformUtils({
				linkUtils,
				sessionService,
				appsPublicApiManager,
				biUtils,
				locationManager,
				essentials,
				warmupDataManager,
				consentPolicyManager,
				clientSpecMapApi,
			})
			const { createSetProps, waitForUpdatePropsPromises, createSetPropsForOOI } = setPropsFactory({
				modelsApi,
				logger,
				handlers,
			})
			const registerEventFactory = RegisterEventFactory({ handlers, modelsApi })
			const animationsApi = PlatformAnimationsAPI({ handlers, platformEnvData, modelsApi })
			const { getSdkFactoryParams } = createSdkFactoryParams({
				animationsApi,
				sdkInstancesCache,
				componentSdkState,
				platformUtils,
				modelsApi,
				createViewerHandlers,
				getCompRefById,
				logger,
				createSetProps,
				registerEventFactory,
				platformEnvData,
				wixCodeNamespacesRegistry,
			})
			const wixSelector = WixSelector({
				bootstrapData,
				modelsApi,
				getSdkFactoryParams,
				controllerEventsFactory,
				sdkInstancesCache,
				componentSdksManager,
				logger,
				unfinishedTasks,
				clientSpecMapApi,
			})
			const reporter = {
				logSdkError,
				logSdkWarning,
			}
			const controllersExports: ControllersExports = {}

			const AppControllerSdkLoader = async () => {
				const { AppControllerSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerSdk({ controllersExports, modelsApi, controllerEventsFactory })
			}

			const AppWidgetSdkLoader = async () => {
				const { AppControllerWithChildrenSdk } = await import('./componentsSDK/AppController' /* webpackChunkName: "AppController.corvid" */)
				return AppControllerWithChildrenSdk({ controllersExports, modelsApi, controllerEventsFactory })
			}

			const staticEventsManager = CreateStaticEventsManager({
				modelsApi,
				controllerEventsFactory,
				wixSelector,
				logger,
			})
			// create here
			const wixCodeViewerAppUtils = WixCodeViewerAppUtils({ bootstrapData, staticEventsManager })
			const blocksAppsUtils = BlocksAppsUtils({ bootstrapData })
			const dataBindingViewerAppUtils = DataBindingViewerAppUtils({ bootstrapData })
			const wixCodeApiFactory = createWixCodeApiFactory({
				bootstrapData,
				wixCodeViewerAppUtils,
				modelsApi,
				clientSpecMapApi,
				platformUtils,
				createViewerHandlers,
				platformEnvData,
				logger,
				wixCodeNamespacesRegistry,
				moduleLoader,
				onPageWillUnmount,
			})

			const createPlatformApiForApp = createPlatformApi({
				platformEnvData,
				platformUtils,
				createStorageApi,
				handlers,
			})

			const moduleFederationManager = ModuleFederationManagerFactory({
				logger,
				moduleLoader,
				appsUrlsManager,
				clientSpecMapApi,
				platformEnvData,
			})

			const { runApplications, createRepeatedControllers } = Applications({
				appsPublicApiManager,
				platformUtils,
				clientSpecMapApi,
				appsUrlsManager,
				modelsApi,
				bootstrapData,
				importScripts,
				wixCodeViewerAppUtils,
				blocksAppsUtils,
				dataBindingViewerAppUtils,
				wixSelector,
				logger,
				wixCodeApiFactory,
				createSetPropsForOOI,
				waitForUpdatePropsPromises,
				controllersExports,
				createPlatformApiForApp,
				bsiManager,
				essentials,
				commonConfig: commonConfigManager.get(),
				handlers,
				moduleFederationManager,
				sdkInstancesCache,
				debugApi,
			})

			const RepeaterSdkLoader = async () => {
				const { RepeaterSdk } = await import('./componentsSDK/repeaters/Repeater' /* webpackChunkName: "Repeater.corvid" */)
				return RepeaterSdk({
					modelsApi,
					wixSelector,
					reporter,
					sdkInstancesCache,
					componentSdkState,
					platformEnvData,
					createRepeatedControllers,
					handlers,
				})
			}

			const DocumentSdkLoader = async () =>
				Promise.resolve(
					DocumentSdkFactory({
						modelsApi,
						wixSelector,
						currentPageId: bootstrapData.currentPageId,
					})
				)

			const coreSdks: CoreSdkLoaders = {
				AppController: AppControllerSdkLoader,
				AppWidget: AppWidgetSdkLoader,
				TPAWidget: AppControllerSdkLoader,
				TPASection: AppControllerSdkLoader,
				TPAMultiSection: AppControllerSdkLoader,
				TPAGluedWidget: AppControllerSdkLoader,
				tpaWidgetNative: AppControllerSdkLoader,
				Repeater: RepeaterSdkLoader,
				Document: DocumentSdkLoader,
			}
			componentSdksManager.fetchComponentsSdks(coreSdks)
			logger.interactionEnded('initialisation')

			await logger.runAsyncAndReport('runApplications', () => runApplications(modelsApi.getApplicationIds()))
			// calling it here because we need to run all the applications, register the controllers APIs, run and finish all PageReady/OnReady, before executing any static events handlers.
			// some handlers may depends on the apis being registered and onReady been called,
			staticEventsManager.triggerStaticEventsHandlers() // TODO do we need to run this is SSR?
		},
	}
}
