import { ManagerSlave } from '@wix/bsi-manager'
import type { CommonConfig, ConsentPolicyManager } from '@wix/thunderbolt-symbols'
import type { ICommonConfigModule } from './commonConfigModule'
import type { BootstrapData } from '../types'

export default function (
	commonConfigModule: ICommonConfigModule,
	consentPolicyManager: ConsentPolicyManager,
	bootstrapData: BootstrapData,
	createViewerHandlers: (pageId: string) => any,
	onPageWillUnmount: (cb: Function) => void
): ManagerSlave {
	const viewerHandlers = createViewerHandlers(bootstrapData.currentPageId)

	const readOnlyCommonConfig = {
		get: (key: keyof CommonConfig) => commonConfigModule.get()[key],
		subscribe: commonConfigModule.registerToChange,
	}

	const bsiManager = new ManagerSlave()
		.init(
			{
				getCommonConfig: () => readOnlyCommonConfig,
				getConsentPolicy: () => consentPolicyManager.getDetails(),
			},
			{ enableCookie: false }
		)
		.onActivity(() => {
			viewerHandlers.reportActivity()
		})

	onPageWillUnmount(() => bsiManager.destroy())

	return bsiManager
}
