import type { CommonConfig } from '@wix/thunderbolt-symbols'
import type { BootstrapData } from '../types'

declare const self: {
	commonConfig: CommonConfig
}

export type ICommonConfigModule = {
	registerToChange: (handler: (commonConfig: CommonConfig) => void) => void
	get: () => CommonConfig
}

export default function (bootstrapData: BootstrapData, createViewerHandlers: (pageId: string) => any, onPageWillUnmount: (cb: Function) => void): ICommonConfigModule {
	const sdkHandlers = createViewerHandlers(bootstrapData.currentPageId)
	const subscribers: Array<(commonConfig: CommonConfig) => void> = []

	if (process.env.browser) {
		sdkHandlers
			.registerToCommonConfigChange((newCommonConfig: CommonConfig) => {
				self.commonConfig = newCommonConfig
				subscribers.forEach((subscriber) => subscriber(newCommonConfig))
			})
			.then(onPageWillUnmount)
	}
	return {
		registerToChange: (handler) => subscribers.push(handler),
		get: () => self.commonConfig,
	}
}
