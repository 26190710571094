import type { BootstrapData } from '../../types'
import type { SessionServiceAPI } from '@wix/thunderbolt-symbols'
import { ViewerPlatformEssentials } from '@wix/fe-essentials-viewer-platform'
import { BOOTSTRAP_DATA, SESSION_SERVICE, PLATFORM_ESSENTIALS } from './moduleNames'

function PlatformEssentials(bootstrapData: BootstrapData, sessionService: SessionServiceAPI) {
	return new ViewerPlatformEssentials({
		conductedExperiments: {},
		isSSR: bootstrapData.platformEnvData.window.isSSR,
		metaSiteId: bootstrapData.platformEnvData.location.metaSiteId,
		appsConductedExperiments: bootstrapData.essentials.appsConductedExperiments,
		getAppToken(appDefId) {
			return sessionService.getInstance(appDefId)
		},
	})
}

export default {
	factory: PlatformEssentials,
	deps: [BOOTSTRAP_DATA, SESSION_SERVICE],
	name: PLATFORM_ESSENTIALS,
}
