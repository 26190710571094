import _ from 'lodash'
import type { BiUtils, PlatformEnvData, ModelsAPI } from '@wix/thunderbolt-symbols'

export const PlatformBI = ({ biUtils, platformEnvData, modelsApi }: { biUtils: BiUtils; platformEnvData: PlatformEnvData; modelsApi: ModelsAPI }) => {
	const biLogger = biUtils.createBaseBiLoggerFactory().logger()

	return {
		reportWidgetsOnPage: () => {
			if (process.env.browser && platformEnvData.bi.pageData.pageNumber === 1 && !platformEnvData.bi.isPreview) {
				const widgetsList = _.map(modelsApi.getApplications(), (controllers, appDefinitionId) => ({
					app_id: appDefinitionId,
					widgets: _(controllers)
						.countBy('controllerType')
						.map((count, controllerType) => ({ widget_id: controllerType, widget_instance_count: count }))
						.value(),
				}))
				biLogger.report({ src: 72, evid: 520, endpoint: 'bpm', params: { widgetsList } })
			}
		},
	}
}
