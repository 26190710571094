import type { BootstrapData } from '../../types'
import { createDeepProxy } from '../../deepProxyUtils'
import type { InvokeViewerHandler, IViewerHandlers } from '../types'
import { BOOTSTRAP_DATA, VIEWER_HANDLERS, INVOKE_VIEWER_HANDLER } from './moduleNames'

export const ViewerHandlers = (invokeViewerHandler: InvokeViewerHandler, bootstrapData: BootstrapData): IViewerHandlers => {
	const createViewerHandlers = (pageId: string) => createDeepProxy((path: Array<string>) => (...args: Array<never>) => invokeViewerHandler(pageId, path, ...args))
	const currentPageViewerHandlers = createViewerHandlers(bootstrapData.currentPageId) as any

	return {
		createViewerHandlers,
		viewerHandlers: currentPageViewerHandlers,
	}
}

export default {
	factory: ViewerHandlers,
	deps: [INVOKE_VIEWER_HANDLER, BOOTSTRAP_DATA],
	name: VIEWER_HANDLERS,
}
