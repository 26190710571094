import _ from 'lodash'
import type { IUnfinishedTasks, IViewerHandlers } from '../types'
import { UNFINISHED_TASKS, VIEWER_HANDLERS } from './moduleNames'

export const UnfinishedTasks = ({ viewerHandlers }: IViewerHandlers): IUnfinishedTasks => {
	return {
		add: (name) => {
			const id = _.uniqueId()
			viewerHandlers.unfinishedTasks.add(id, name)

			return () => {
				viewerHandlers.unfinishedTasks.remove(id)
			}
		},
	}
}

export default {
	factory: UnfinishedTasks,
	deps: [VIEWER_HANDLERS],
	name: UNFINISHED_TASKS,
}
